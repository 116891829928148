import { Box } from '@mui/system'
import { DataGrid, ToolTip, VerifiedIcon, WarningIcon, ErrorIcon, Loading } from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import ProductDialogue from './ProductDialogue'
import { FindFolderIdByPath, constructFolderPathById } from './FolderHelper'
import { useSessionAccount } from '@papertrail/web3-session'
import { useApiGet } from '@papertrail/web3-utils'
import { useParams } from 'react-router-dom'
import { useApiGetFolders } from './hooks'
import { Frequency, State } from 'src/types'
import { ArrToString } from './ImportHelper'

type Props = {
  validatedData
  Products
  blueprintState
  updatedData
  loading
  meta
  setPagination
}

type Params = {
  accountid: string
}

export const AddToPapertrail = (props: Props) => {
  const { accountid } = useParams() as Params
  const { validatedData, Products, blueprintState, updatedData, loading, meta, setPagination } = props
  const [dataWithProducts, setDataWithProducts] = useState(null)
  const [showProduct, setShowProduct] = useState<boolean>(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const account = useSessionAccount()
  const [stateList, loadStates] = useApiGet<State[]>(`/accounts/${accountid}/states`, (data) => {
    const states = Mapper(data)
    return states
  })
  const [frequencyList, loadFrequencies] = useApiGet<Frequency[]>(`/accounts/${accountid}/frequencies`, (data) => {
    const frequencies = Mapper(data)
    return frequencies
  })

  const [folders, setFolders] = useState([])
  const rootFolderId = account ? account.rootFolderId : undefined
  const [folderList, loadFolders] = useApiGetFolders(accountid, rootFolderId, folders)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false)
  const [allListsLoaded, setAllListsLoaded] = useState<boolean>(false)

  const Mapper = (data) => {
    const arr = data.data.map((item) => {
      return {
        value: item.name.toLowerCase(),
        label: item.name,
        id: item.id
      }
    })
    arr.sort((a, b) => a.name - b.name)
    return arr
  }

  useEffect(() => {
    if (account) {
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [account])

  useEffect(() => {
    if (folderList.isLoaded) {
      setFolders(folderList.data)
    }
  }, [folderList])

  useEffect(() => {
    if (accountid) {
      loadFrequencies({})
      loadStates({})
    }
  }, [accountid])

  useEffect(() => {
    if (
      frequencyList.isLoaded &&
      stateList.isLoaded &&
      folders &&
      folders.length > 0 &&
      blueprintState &&
      blueprintState.length > 0 &&
      validatedData &&
      Products
    ) {
      setAllListsLoaded(true)
    }
  }, [frequencyList.isLoaded, stateList.isLoaded, folders, blueprintState && validatedData])

  useEffect(() => {
    if (allListsLoaded && validatedData && Products) {
      if (validatedData) {
        setDataWithProducts(setImportGridData(resetIds(validatedData)))
      }
    }
  }, [allListsLoaded, validatedData, Products])

  const setImportGridData = (importData) => {
    return importData.map((row) => {
      // Create a new object excluding properties that start with 'error'
      const filteredRow = Object.keys(row).reduce((acc, key) => {
        if (!key.startsWith('errors')) {
          acc[key] = row[key]
        }
        return acc
      }, {})

      // Update the filtered row with additional properties
      const updatedRow: any = {
        product_match: row.product_id ? 1 : checkForProductMatch(row.id),
        ...filteredRow
      }

      // Set the product_id if not already present
      updatedRow.product_id = row.product_id ? row.product_id : checkForProductId(row.id)

      return updatedRow
    })
  }

  useEffect(() => {
    if (
      frequencyList.isLoaded &&
      stateList.isLoaded &&
      folders &&
      folders.length > 0 &&
      blueprintState &&
      blueprintState.length > 0 &&
      dataWithProducts &&
      dataWithProducts.length > 0
    ) {
      setAllDataLoaded(true)
    }
  }, [frequencyList.isLoaded, stateList.isLoaded, folders, blueprintState && dataWithProducts])

  const checkForProductMatch = (id) => {
    const product = Products[id]
    const productMatches = product ? product.length : 0
    return productMatches
  }

  const checkForProductId = (id) => {
    const product = Products[id]
    if (product && product.length === 1) {
      return product[0]
    } else {
      return null
    }
  }

  const resetIds = (data) => {
    const resetData = data.map((row) => {
      const originalState = findNameById(stateList.data, row.state)
      const originalFrequency = findNameById(frequencyList.data, row.frequency)
      const originalFolderPath =
        constructFolderPathById({ id: 'root', name: account.name, children: folders }, row.folder) || ''

      const newRow = {
        ...row,
        state: originalState,
        frequency: originalFrequency,
        folder: originalFolderPath
      }

      const rowWithoutArrays = ArrToString(newRow, blueprintState)

      return rowWithoutArrays
    })
    return resetData
  }

  const findNameById = (list, id) => {
    const item = list.find((item) => item.id === id)
    return item ? item.value : null
  }

  const renderHeader = (params) => {
    const cellStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600
    }
    const index = blueprintState.findIndex((item) => item.value === params.field)
    const myField = blueprintState[index]
      ? blueprintState[index]
      : {
          label:
            (params.field === 'product_match' && 'Product Match') || (params.field === 'product_id' && 'Product ID')
        }

    return <div style={cellStyle}>{myField.label}</div>
  }

  const customCell = (params) => {
    const formatValue = () => {
      if (params.field === 'product_match') {
        return productMatchCell(params.value)
      } else return params.value ? params.value : ' '
    }

    const formatTooltip = () => {
      if (params.field === 'product_match') {
        return productMatchTooltip(params.value)
      } else return params.value ? params.value : ' '
    }

    const productMatchTooltip = (value) => {
      switch (value) {
        case 1:
          return 'Exact Match Found'
          break
        case 0:
          return 'No Matches Found'
          break
        default:
          return 'Multiple Matches Found'
          break
      }
    }

    const cellStyle = {
      height: '95%',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '0 10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    const openModal = () => {
      if (params.field !== 'product_match') {
        return
      }
      setSelectedRow(params.row)
      setShowProduct(true)
    }

    return (
      <Box sx={cellStyle} onClick={openModal}>
        <ToolTip title={formatTooltip()}>
          <div>{formatValue()}</div>
        </ToolTip>
      </Box>
    )
  }

  const productMatchCell = (value) => {
    switch (value) {
      case 1:
        return <VerifiedIcon color="success" />
        break
      case 0:
        return <ErrorIcon color="error" />
        break
      default:
        return <WarningIcon color="warning" />
        break
    }
  }

  const onProductConfirmed = (product) => {
    const confData = dataWithProducts
    const index = confData.findIndex((item) => item.id === selectedRow.id)
    confData[index].product_id = product.id
    confData[index].product_match = 1
    confData[index].product_code = product.fields.data.find((item) => item.key === 'product_code')
      ? product.fields.data.find((item) => item.key === 'product_code').value
      : ''
    setDataWithProducts(confData)
    setSelectedRow(null)
    const formattedData = confData.map((item) => {
      const { product_match, ...rest } = item
      return rest
    })
    const DataWithIds = setIds([formattedData[index]])
    updatedData(DataWithIds[0])

    setShowProduct(false)
  }

  const setIds = (data) => {
    const newData = data.map((row) => {
      const newState = returnId(stateList.data, row.state)
      const newFrequency = returnId(frequencyList.data, row.frequency)
      const normalizedPath = row.folder.startsWith('/') ? row.folder : `/${row.folder}`
      const newFolder =
        FindFolderIdByPath(account.name, `${normalizedPath}`, {
          id: 'root',
          name: account.name,
          children: folders
        }) || null

      const newTags = row.tags ? row.tags.split(',') : null
      const newCertifications = row.certifications ? row.certifications.split(',') : null

      const newRow: any = {
        ...row,
        frequency: newFrequency,
        folder: newFolder,
        state: newState
      }

      if (newTags) {
        newRow.tags = newTags
      }
      if (newCertifications) {
        newRow.certifications = newCertifications
      }

      return newRow
    })

    return newData
  }

  const returnId = (list, value) => {
    const index = value ? list.findIndex((item) => item.value.toLowerCase() === value.toLowerCase()) : -1
    return index !== -1 ? list[index].id : null
  }

  const pastedValueParser = (value, params) => {
    return value
  }

  if (dataWithProducts && dataWithProducts.length > 0) {
    return (
      <>
        <Box marginBottom={'250px'}>
          <DataGrid
            setPaginationModel={setPagination}
            meta={meta}
            loading={loading}
            check={false}
            initialData={dataWithProducts}
            updatedRowValue={(value) => console.log(value, 'value')}
            filters={null}
            pinnedColumns={{
              left: ['id', 'product_match', 'product_code', 'manufacturer']
            }}
            columns={
              dataWithProducts && dataWithProducts.length > 0
                ? Object.keys(dataWithProducts[0]).map((field) => ({
                    field: field,
                    headerName: field,
                    width: 100,
                    align: 'center',
                    headerAlign: 'center',
                    renderHeader: (params) => renderHeader(params),
                    renderCell: (params) => customCell(params),
                    renderEditCell: (params) => customCell(params),
                    pastedValueParser: pastedValueParser
                  }))
                : []
            }></DataGrid>
        </Box>
        {showProduct && (
          <ProductDialogue
            products={Products}
            row={selectedRow}
            onCancel={() => setShowProduct(false)}
            onConfirm={onProductConfirmed}
          />
        )}
      </>
    )
  } else {
    return (
      <Box>
        <Loading />
      </Box>
    )
  }
}
