export const blueprintMapper = (data) => {
  const blueprint = data.data.blueprint
  return {
    id: blueprint.data.id,
    fields: getFieldsAsArray(blueprint.data.sections)
  }
}

export const getFieldsAsArray = (sections) => {
  const array = []
  sections.data.forEach((section) => {
    section.fields.data.forEach((field) => {
      if (field.label.toLowerCase() !== 'inspection frequency') {
        array.push({
          id: field.id,
          type: field.type,
          label: field.label,
          value: field.key,
          required: field.required
        })
      }
    })
  })
  const mergedArrays = [...array, ...extraFields]
  const sortedArr = []

  for (const item of mergedArrays) {
    if (!sortedArr.some((existingItem) => existingItem.value === item.value)) {
      sortedArr.push(item)
    }
  }

  sortedArr.sort((a, b) => a.value.localeCompare(b.value))
  return sortedArr
}

export const extraFields = [
  { id: '000', type: 'number', label: 'ID', value: 'id', required: true },
  { id: '001', type: 'string', label: 'Folder', value: 'folder', required: true },
  { id: '002', type: 'string', label: 'Frequency', value: 'frequency', required: true },
  { id: '003', type: 'string', label: 'State', value: 'state', required: true },
  { id: '004', type: 'string', label: 'Comments', value: 'comments', required: true },
  { id: '005', type: 'array', label: 'Tags', value: 'tags', required: true },
  { id: '006', type: 'string', label: 'Name', value: 'name', required: true },
  { id: '007', type: 'date', label: 'Next Check At', value: 'next_check_at', required: true }
]
