import { Box } from '@mui/system'
import {
  ButtonDanger,
  ButtonPrimary,
  ButtonSecondary,
  CloseIcon,
  CustomGridEditDateCell,
  DataGrid,
  FlexRow,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridEditInputCell,
  GridEditSingleSelectCell,
  GridRenderEditCellParams,
  Loading,
  ToolTip
} from '@papertrail/styleguide'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSessionAccount } from '@papertrail/web3-session'
import FolderTree from './FolderTree'
import { constructFolderPathById } from './FolderHelper'
import { useParams } from 'react-router-dom'
import Cell from './Cell'
import { Dialog, DialogContent, DialogTitle, Switch } from '@mui/material'
import { checkType, checkValue, convertStringToDate, findNameById } from './CellHelper'
import { ArrToString, setIds } from './ImportHelper'
import { useApiPost } from '@papertrail/web3-utils'

type Props = {
  loading
  meta
  rowData: any
  status
  importId
  blueprintState: any
  frequencyList
  stateList
  folders
  dataForSubmission: (data) => void
  setPagination: (data) => void
  setErrorsOnly: (data) => void
  refresh
  validate
  errors
}

type Params = {
  accountid: string
}

export const ValidateData = (props: Props) => {
  const {
    blueprintState,
    frequencyList,
    stateList,
    folders,
    rowData,
    status,
    dataForSubmission,
    loading,
    meta,
    setPagination,
    setErrorsOnly,
    importId,
    refresh,
    validate,
    errors
  } = props
  const [data, setData] = useState(null)

  const account = useSessionAccount()
  const [undoStack, setUndoStack] = useState([])
  const [undoDisable, setUndoDisable] = useState(true)
  const { accountid } = useParams() as Params
  const [gridFilters, setGridFilters] = useState(false)
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const [showDeleteRows, setShowDeleteRows] = useState(false)
  const [dataToUndo, setDataToUndo] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addNewRow, setAddNewRow] = useApiPost(
    `/accounts/${accountid}/data_import/${importId}/rows/create`,
    (data) => data
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteRows, setDeleteRows] = useApiPost(
    `/accounts/${accountid}/data_import/${importId}/rows/delete`,
    (data) => data
  )
  const [importStatus, setImportStatus] = useState(status)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    return setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (
      frequencyList.isLoaded &&
      stateList.isLoaded &&
      folders &&
      folders.length > 0 &&
      blueprintState &&
      blueprintState.length > 0 &&
      rowData
    ) {
      setAllDataLoaded(true)
    }
  }, [frequencyList.isLoaded, stateList.isLoaded, folders, blueprintState && rowData])

  useEffect(() => {
    if (allDataLoaded) {
      console.log(errors, 'errors')
      setImportData(rowData)
    }
  }, [allDataLoaded, rowData])

  useEffect(() => {
    // Update undoDisable state based on the length of the undoStack
    setUndoDisable(undoStack.length === 0)
  }, [undoStack])

  useEffect(() => {
    setImportStatus(status)
  }, [status])

  const setGridData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        name: row.name && row.name.length > 0 ? row.name : 'value required',
        state: row.state && row.state !== '' ? row.state.toLowerCase() : 'value required',
        frequency: row.frequency && row.frequency !== '' ? row.frequency.toLowerCase() : 'value required',
        folder: row.folder && row.folder !== '' ? row.folder : 'value required'
      }
    })
  }

  const setImportData = (data) => {
    const incorrectRowData = data.map((row) => {
      const { product_match, errors, ...rest } = row
      return rest
    })
    const correctRowData = resetIds(incorrectRowData)
    setData(setGridData(correctRowData))
  }

  const checkOptions = (key) => {
    switch (key) {
      case 'frequency':
        return addSelectOption(frequencyList.data, 'frequency')
      case 'state':
        return addSelectOption(stateList.data, 'state')
      default:
        return null
    }
  }

  const addSelectOption = (options, fieldName) => {
    const selectOption = { value: `${fieldName} required`, label: `Select ${fieldName}` }
    return options ? [selectOption, ...options] : [selectOption]
  }
  const renderHeader = useCallback(
    (params) => {
      const cellStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600
      }

      const myField = useMemo(() => {
        const index = blueprintState.findIndex((item) => item.value === params.field)
        return blueprintState[index] ? blueprintState[index] : { label: 'Valid' }
      }, [params.field, blueprintState])

      return (
        <div style={cellStyle}>
          {myField.label} {myField.type === 'money' && ` (${account.currency})`}
        </div>
      )
    },
    [blueprintState, account.currency]
  )

  const EditInputCell = (props: GridRenderEditCellParams) => {
    const { error, colDef, ...otherProps } = props
    const type = colDef.type

    const cellStyle = {
      backgroundColor: !error ? 'inherit' : '#FDEBEC',
      height: '95%',
      width: '100%',
      padding: '0px 4px',
      border: !error ? 'none' : '1px solid #EB3745',
      boxShadow: !error ? 'none' : '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textWrap: 'wrap'
    }

    if (type === 'singleSelect') {
      if (colDef.field === 'folder') {
        return (
          <div id="folder-cell">
            <FolderTree folders={folders} account={account} gridProps={props}></FolderTree>
          </div>
        )
      } else {
        const newValue = checkValue(otherProps.value, colDef.field, stateList, frequencyList, account, folders)
          ? otherProps.value
          : ''
        return <GridEditSingleSelectCell onValueChange={null} {...otherProps} value={newValue} colDef={colDef} />
      }
    }
    if (type === 'date') {
      let formattedDate
      if (typeof otherProps.value === 'string' && otherProps.value) {
        formattedDate = convertStringToDate(otherProps.value, 'yyyy-mm-dd')
      } else {
        formattedDate = otherProps.value
      }

      return <CustomGridEditDateCell colDef={colDef} {...otherProps} value={formattedDate} />
    }
    if (colDef.field === 'id') {
      return <Box>{otherProps.value}</Box>
    } else {
      return (
        <GridEditInputCell
          sx={cellStyle}
          {...otherProps}
          value={otherProps.value !== 'value required' ? otherProps.value : ''}
          colDef={colDef}
        />
      )
    }
  }

  const checkUpdatedData = (updatedRow) => {
    const newDataArr = [updatedRow]
    if (newDataArr && newDataArr.length > 0) {
      const dataWithIds = setIds(newDataArr, stateList, frequencyList, account, folders, blueprintState)
      dataForSubmission(dataWithIds[0])
    }
  }

  const setValidFilter = () => {
    setGridFilters(!gridFilters ? true : false)
    setErrorsOnly(!gridFilters ? true : false)
  }

  const resetIds = (data) => {
    const resetData = data.map((row) => {
      const originalState = findNameById(stateList.data, row.state)
      const originalFrequency = findNameById(frequencyList.data, row.frequency)
      const originalFolderPath =
        constructFolderPathById({ id: 'root', name: account.name, children: folders }, row.folder) || ''

      // Create a new object with the original data
      const newRow = {
        ...row,
        state: originalState,
        frequency: originalFrequency,
        folder: originalFolderPath
      }

      const rowWithoutArrays = ArrToString(newRow, blueprintState)

      return rowWithoutArrays
    })
    return resetData
  }

  const pastedValueParser = (value, row, col) => {
    if (!value || value === null || value === 'null' || value === '' || value === 'undefined') {
      return
    }
    if (col.type === 'date') {
      try {
        return new Date(value)
      } catch (error) {
        return value
      }
    }
    if (col.type === 'number' && !isNaN(value)) {
      return parseFloat(value).toFixed(2)
    } else {
      return value
    }
  }

  const checkValueChange = (updatedRow, originalRow) => {
    setUndoStack([...undoStack, originalRow])
    checkUpdatedData(updatedRow)
    setDataToUndo(null)
  }

  const undo = () => {
    if (undoStack.length === 0) {
      return
    }

    setUndoStack((prevUndoStack) => {
      const prevRow = prevUndoStack.pop()

      if (prevRow) {
        checkUpdatedData(prevRow)
        setUndoDisable(prevUndoStack.length === 0)
        setDataToUndo(prevRow)
      }
      return [...prevUndoStack]
    })
  }

  const removeRows = () => {
    const deleteRows = new CustomEvent('deleteRows')
    document.dispatchEvent(deleteRows)
  }

  const addRow = () => {
    const addRow = new CustomEvent('addRow')
    document.dispatchEvent(addRow)
  }

  const errorGroups: Record<string, number[]> = errors.reduce((acc, row) => {
    row.errors.forEach((error) => {
      const key = error.message_text
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(row.id)
    })
    return acc
  }, {})

  if (frequencyList.isLoaded && folders && stateList.isLoaded && data && data.length > 0) {
    return (
      <Box marginBottom={'100px'}>
        <FlexRow justifyContent={'space-between'} sx={{ marginBottom: '4px' }}>
          <div>
            <ToolTip
              title={
                importStatus === 'Validated' || importStatus === 'Initial' || importStatus === 'Validating'
                  ? 'Validate your import to check for any errors'
                  : ''
              }>
              <Switch
                disabled={importStatus === 'Validated' || importStatus === 'Initial' || importStatus === 'Validating'}
                checked={gridFilters}
                onChange={setValidFilter}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              Show only rows with errors
            </ToolTip>
          </div>
          <FlexRow justifyContent={'space-between'}>
            {showDeleteRows === true && !gridFilters && (
              <Box marginRight={'8px'}>
                <ButtonDanger onClick={() => removeRows()}>Remove Row(s)</ButtonDanger>
              </Box>
            )}
            {!gridFilters && <ButtonPrimary onClick={addRow}>Add Row</ButtonPrimary>}

            <Box marginLeft={'8px'} onClick={handleClick}>
              <ButtonSecondary onClick={null} disabled={!errors || !errors.length}>
                Error Log
              </ButtonSecondary>
            </Box>

            <Box marginLeft={'8px'}>
              <ButtonSecondary onClick={undo} disabled={undoDisable}>
                Undo
              </ButtonSecondary>
            </Box>
            <Box marginLeft={'8px'}>
              <ButtonSecondary onClick={validate}>Validate</ButtonSecondary>
            </Box>
          </FlexRow>
        </FlexRow>
        <DataGrid
          meta={meta}
          initialData={data}
          undoLastChange={dataToUndo}
          updatedRowValue={(updatedRow, originalRow) => checkValueChange(updatedRow, originalRow)}
          setPaginationModel={setPagination}
          handleProcessRowUpdate={undo}
          filters={null}
          check={true}
          loading={loading}
          pinnedColumns={{
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'id', 'name', 'folder', 'frequency', 'state']
          }}
          updatedRowSelection={(rows) => {
            setShowDeleteRows(rows && rows.length > 0)
          }}
          newRow={(data) => {
            setAddNewRow({ data_type: 'record', rows: [data] })
            refresh()
          }}
          rowsToDelete={(rows) => {
            setDeleteRows({ rows: rows })
            refresh()
          }}
          columns={
            data && data.length > 0
              ? Object.keys(data[0]).map((field) => ({
                  field: field,
                  headerName: field,
                  width: 100,
                  align: 'center',
                  headerAlign: 'center',
                  editable: true,
                  renderHeader: (params) => renderHeader(params),
                  type: checkType(field, blueprintState),
                  valueOptions: checkOptions(field),
                  renderCell: (params) => (
                    <Cell
                      gridProps={params}
                      blueprintState={blueprintState}
                      account={account}
                      folders={folders}
                      stateList={stateList}
                      frequencyList={frequencyList}
                    />
                  ),
                  renderEditCell: (params) => EditInputCell(params),
                  valueGetter: (params) => {
                    if (checkType(field, blueprintState) === 'date' && params !== 'undefined') {
                      return convertStringToDate(params, 'yyyy-mm-dd')
                    }
                    if (checkType(field, blueprintState) === 'number' && params && field !== 'id' && !isNaN(params)) {
                      return parseFloat(params).toFixed(2) ? parseFloat(params).toFixed(2) : params
                    } else return params
                  },
                  pastedValueParser: pastedValueParser
                }))
              : []
          }></DataGrid>
        <Dialog open={open}>
          <Box>
            <DialogTitle>
              <FlexRow justifyContent={'space-between'}>
                <h1>Errors for current page</h1>
                <Box onClick={() => setAnchorEl(null)} sx={{ cursor: 'pointer' }}>
                  <CloseIcon />
                </Box>
              </FlexRow>
            </DialogTitle>
            <DialogContent sx={{ width: '400px' }}>
              {Object.entries(errorGroups).map(([message, rowIds]) => (
                <Box key={message} justifyContent="center" maxWidth="400px" sx={{ overflow: 'auto', mb: 2 }}>
                  <strong>Error:</strong> {message}
                  <br />
                  <strong>Rows:</strong> {rowIds.join(', ')}
                </Box>
              ))}
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    )
  } else {
    return (
      <Box>
        <Loading />
      </Box>
    )
  }
}
