import { Box } from '@mui/system'
import { ToolTip } from '@papertrail/styleguide'
import React, { useState, useEffect, useMemo } from 'react'
import { formatDateToString, isValidCellValue } from './CellHelper'

type Props = {
  gridProps
  blueprintState
  account
  folders
  stateList
  frequencyList
}

const Cell = React.memo((props: Props) => {
  const [isValid, setIsValid] = useState(true)
  const { gridProps, blueprintState, account, folders, stateList, frequencyList } = props

  useEffect(() => {
    if (gridProps) {
      setIsValid(
        isValidCellValue(gridProps.field, gridProps.value, blueprintState, stateList, frequencyList, account, folders)
      )
    }
  }, [gridProps, blueprintState, account, folders, stateList, frequencyList])

  const cellStyle = useMemo(
    () => ({
      backgroundColor: isValid ? 'inherit' : '#FDEBEC',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '0 10px',
      border: isValid ? 'none' : '1px solid #EB3745',
      boxShadow: isValid ? 'none' : '2px 0px 4px -2px rgba(0, 0, 0, 0.21)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }),
    [isValid]
  )

  const formatValue = useMemo(() => {
    if (gridProps.colDef.type === 'date' && gridProps.value) {
      return formatDateToString(gridProps.value)
    }
    if (
      (gridProps.field === 'name' ||
        gridProps.field === 'field' ||
        gridProps.field === 'frequency' ||
        gridProps.field === 'state') &&
      !gridProps.value
    ) {
      return 'value required'
    } else {
      return gridProps.value
    }
  }, [gridProps])

  const formatTooltip = useMemo(() => {
    if (!isValid) {
      return `You have provided an incorrect ${gridProps.field} value`
    } else {
      return formatValue
    }
  }, [isValid, gridProps.field, formatValue])

  return (
    <Box sx={cellStyle}>
      <ToolTip title={formatTooltip}>
        <div>{formatValue}</div>
      </ToolTip>
    </Box>
  )
})

Cell.displayName = 'Cell'

export default Cell
