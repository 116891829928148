import { formatDateToString, returnId } from './CellHelper'
import { FindFolderIdByPath } from './FolderHelper'

export const setIds = (data, stateList, frequencyList, account, folders, blueprintState) => {
  const newData = data.map((row) => {
    const newState = returnId(stateList.data, row.state)
    const newFrequency = returnId(frequencyList.data, row.frequency)
    const normalizedPath = row.folder.startsWith('/') ? row.folder : `/${row.folder}`
    const newFolder =
      FindFolderIdByPath(account.name, `${normalizedPath}`, {
        id: 'root',
        name: account.name,
        children: folders
      }) || row.folder

    // Create the new object with only non-null properties

    const newRow: any = {
      ...updateDateMoneyArrValues(row, blueprintState),
      frequency: newFrequency,
      folder: newFolder === 'root' ? 'value required' : newFolder,
      state: newState
    }

    return newRow
  })

  return newData
}

const updateDateMoneyArrValues = (row, blueprintState) => {
  const updatedRow = Object.keys(row).reduce((acc, key) => {
    const blueprintField = blueprintState.find((field) => field.value === key)

    if (blueprintField) {
      const value = row[key]

      if (!value || value === '' || value === 'null') {
        acc[key] = null
      } else {
        if (blueprintField.type === 'date') {
          acc[key] = formatDateToString(value) ? formatDateToString(value) : value
        } else if (blueprintField.type === 'money') {
          acc[key] = parseFloat(value).toFixed(2)
        } else if (blueprintField.type === 'array') {
          acc[key] = value.split(',')
        } else {
          acc[key] = value
        }
      }
    } else {
      acc[key] = row[key]
    }
    return acc
  }, {})

  return updatedRow
}

export const ArrToString = (row, blueprintState) => {
  const updatedRow = Object.keys(row).reduce((acc, key) => {
    const blueprintField = blueprintState.find((field) => field.value === key)

    if (blueprintField) {
      const value = row[key]

      if (blueprintField.type === 'array' && value && value.length) {
        acc[key] = value.join(',')
      } else {
        acc[key] = row[key]
      }
    } else {
      acc[key] = row[key]
    }
    return acc
  }, {})

  return updatedRow
}
